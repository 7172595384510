export default defineNuxtRouteMiddleware((to) => {
  // skip middleware on server
  if (process.server) return

  const { isAuthenticated } = useUser()
  const localePath = useLocalePath()

  if (isAuthenticated.value) {
    return navigateTo(localePath({ name: "index" }), { redirectCode: 301 })
  }
})
